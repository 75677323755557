import { Component, createRef } from 'preact';
import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';

import Logo from '../SVG/Logo';
import Dropdown from '../Dropdown';

class Header extends Component {
	constructor(props)
	{
		super(props);

		this.state = {
			header: false
		}
	}

	DropdownRef = createRef();

	static hrefs = [
		{ href: '/', name: 'Strona główna' },
		{ href: '/biografia', name: 'Biografia' },
		{ href: '/zbiory', name: 'Zbiory' },
		{ href: '/pielgrzymki', name: 'Pielgrzymki' }
	]

	render() {
		return (
			<div>
				<Dropdown ref={e => this.DropdownRef = e} hrefs={Header.hrefs}/>
				<header class={"header header--front " + (this.state.header ? "header--front header--fixed" : "")}>
				<div class="container-fluid">
					<div class="row no-gutters justify-content-between">
						<div class="col-3 d-flex align-items-center">
							<div onClick={(() => {
								if(this.DropdownRef != null)
								{
									this.DropdownRef.Open();
								}
							})} class="dropdown-trigger d-none d-sm-block">
								<div class="dropdown-trigger__item"></div>
							</div>
							<div class="header-logo">
								<a class="header-logo__link" href="/" style="visibility: hidden">
									<Logo/>
								</a></div>
						</div>
						<div class="col-5 text-center">
							<nav>
								<ul class="main-menu">

									{Header.hrefs.map((answer, i) => {
										if (typeof window !== "undefined") {
											if (answer.href.startsWith("/zbiory") && document.location.pathname.startsWith("/zbiory")) {
												return (
													<li class="main-menu__item main-menu__item--has-child ">
														<Link class={"main-menu__link active"} href={answer.href}><span>{answer.name}</span></Link>
													</li>
												)
											}
											else if (answer.href.startsWith("/pielgrzymki") && document.location.pathname.startsWith("/pielgrzymki")) {
												return (
													<li class="main-menu__item main-menu__item--has-child ">
														<Link class={"main-menu__link active"} href={answer.href}><span>{answer.name}</span></Link>
													</li>
												)
											}
											else {
												return (
													<li class="main-menu__item main-menu__item--has-child ">
														<Link activeClassName="active" class={"main-menu__link "} href={answer.href}><span>{answer.name}</span></Link>
													</li>
												)
											}
										}
									})}

								</ul>
							</nav>
						</div>
						<div class="col-3 d-flex align-items-center justify-content-end">
							<div onClick={(() => {
								if(this.DropdownRef != null)
								{
									this.DropdownRef.Open();
								}
							})} class="dropdown-trigger d-block d-sm-none">
								<div class="dropdown-trigger__item"></div>
							</div><a style="visibility: hidden" class="button button--squared"><span>Zgłoś swój film</span></a>
						</div>
					</div>
				</div>
			</header>
			</div>
		)
	}

}

export default Header;
