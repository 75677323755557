import { h, Component, createRef } from 'preact';
import { Router } from 'preact-router';
import { route } from 'preact-router';

import Match from 'preact-router/match';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Header from '../components/header';

import Biografia from '../routes/biografia';
import Zbiory from '../routes/zbiory';
import Pielgrzymki from '../routes/pielgrzymki';

import LazyLoad from 'vanilla-lazyload';

import mainAnimation from '../components/mainAnimation'

import Footer from '../components/Footer';

class App extends Component {

	HeaderRef = createRef();

	constructor(props)
	{
		super(props)

		if (typeof window !== "undefined") { 
			App.lazyLoadInstance = new LazyLoad();
		
			window.mainAnimation = mainAnimation;
		}
	}
	static lazyLoadInstance = null

	UpdateLazyLoad() {
		if(Boolean(document.querySelector("[data-src]:not([src])")))
		{
			App.lazyLoadInstance.update();
			setTimeout(() => {
				this.UpdateLazyLoad();
			}, 100);
		}
		else
		{
			setTimeout(() => {
				this.UpdateLazyLoad();
			}, 100);
		}
	}

	componentDidMount() {
		if (typeof window !== "undefined") { 
			this.UpdateLazyLoad();
		}

		this.SetHeaderFixed();

		window.addEventListener('scroll', () => this.updateScroll());
    }

	updateScroll(){
        if(typeof window !== "undefined" && this.HeaderRef.current != null)
        {
			if(document.location.pathname == "" || document.location.pathname == "/")
			{
				if (window.pageYOffset > 0) {
					this.HeaderRef.current.setState({ header: true })
				}
				else {
					this.HeaderRef.current.setState({ header: false })
				}
			}
        }
    }

	SetHeaderFixed() {
		if(this.HeaderRef.current != null)
		{
			if (document.location.pathname != "" && document.location.pathname != "/") {
				this.HeaderRef.current.setState({ header: true })
			}
			else {
				this.HeaderRef.current.setState({ header: false })
			}
		}
	}

	handleRoute = e => {

		if (typeof window !== "undefined") {
			var url = "";
			if (document.location.pathname == "/") {
				url = "";
			}
			else {
				url = document.location.pathname;
			}
			fetch(url + '/preact_prerender_data.json')
				.then(e => {
					return e.json();
				})
				.then(e => {
					if (e != null) {
						if (e.name != null) {
							document.title = e.name;
						}
						if (e.description != null) {
							document.querySelector('meta[name="description"]').setAttribute("content", e.description.replace('"', ''));
						}
					}
				});
		}
        

		if (typeof window !== "undefined") {
			if (document.location.pathname != "" && document.location.pathname != "/") {
				mainAnimation.clear();
			}
			if (document.location.pathname == "/pielgrzymki" || document.location.pathname == "/pielgrzymki/") {
				route("/pielgrzymki/I");
			}
		}
		

		if(this.HeaderRef.current != null)
		{
			if(this.HeaderRef.current.DropdownRef != null)
			{
				this.HeaderRef.current.DropdownRef.Close();
			}
		}

		
		
		this.SetHeaderFixed();

		if (typeof window !== "undefined") {
			window.scrollTo(0, 0);
		}
	};

	render() {


		return (
			<div id="app" style="width: 100%;">

				<Header ref={this.HeaderRef}/>

				<div style="min-height: 100vh">
					<Router onChange={this.handleRoute}>
						<Home path="/" />
						<Biografia path="/biografia" />
						<Zbiory path="/zbiory/:search?" />
						<Pielgrzymki path="/pielgrzymki/:opt?" />
					</Router>
				</div>

				{/* <Match path="/">
					{({ matches, path, url }) => (
						(path != "/" && path != "")
						?
						<Footer/>
						:
						""
					)}
				</Match> */}

				<Footer/>

			</div>
		)

	}

}

export default App;
