export default {mainAnimation, clear};

var itr = 0;

function clear(){

    itr = 0;
    if(!Boolean(window.MainAnimationListTM))
    {
        window.MainAnimationListTM = [];
    }
    for (var i = 0; i < window.MainAnimationListTM.length; i++) {
        clearTimeout(window.MainAnimationListTM[i]);
    }
}

function mainAnimation() {

    if (!Boolean(document.querySelectorAll(".img-fluid.slideShow").length)) {

        document.querySelectorAll(".main-container .slideShow").forEach(e => {
            e.classList.remove("addScale");
        });

        clear();
        return;
    }

    if (itr == document.querySelectorAll(".main-container .slideShow").length) {

        itr = 0;
    }

    var e = document.querySelectorAll(".main-container .slideShow")[itr];

    e.classList.add("addScale");


    itr++;

    var timeout = setTimeout(function () {
        mainAnimation();
        setTimeout(function () 
        { 

            e.classList.remove("addScale");
        }, 500)
    }, 8000)

    if(!Boolean(window.MainAnimationListTM))
    {
        window.MainAnimationListTM = [];
    }
    
    window.MainAnimationListTM.push(timeout);


}

