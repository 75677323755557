import { Component, createRef } from 'preact';
import { Link } from 'preact-router/match';

import Close from '../components/SVG/Close';

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownClass: false
    }
  }

  Open()
  {
    this.setState({ dropdownClass: true })
  }

  Close()
  {
    this.setState({ dropdownClass: false })
  }

  InnerClick(e)
  {
    if(this.MainRef != null)
    {
      if(e.target == this.MainRef)
      {
        this.Close();
      }
    }
  }

  MainRef = createRef();

  render() {
    return (
      <div ref={e => this.MainRef = e} onClick={((e) => this.InnerClick(e))} class={"aside-dropdown " + (this.state.dropdownClass ? "aside-dropdown--active" : "")}>
        <div  class="aside-dropdown__inner">
          <span class="aside-dropdown__close" onClick={(() => this.Close())}>
            <Close />
          </span>
          <div class="aside-dropdown__item d-lg-none d-block">
            <ul class="aside-menu">

              {this.props.hrefs.map((data) => {
                if (typeof window !== "undefined") {
                  if(data.href == "/")
                  {
                    return (
                      <li class="aside-menu__item aside-menu__item--has-child">
                        <Link href={data.href} class={"aside-menu__link " + ((document.location.pathname == data.href) ? "aside-bolded" : "")}><span>{data.name}</span></Link>
                      </li>
                    )
                  }
                  else
                  {
                    return (
                      <li class="aside-menu__item aside-menu__item--has-child">
                        <Link href={data.href} class={"aside-menu__link " + ((document.location.pathname.toLowerCase().startsWith(data.href.toLowerCase())) ? "aside-bolded" : "")}><span>{data.name}</span></Link>
                      </li>
                    )
                  }
                }

              })}



            </ul>
          </div>
          <div class="aside-dropdown__item">
            <ul class="aside-menu" style="display: none">
              <li class="aside-menu__item"><a class="aside-menu__link" href="#">Kontakt</a></li>
              <li class="aside-menu__item"><a class="aside-menu__link" href="#">O mnie</a></li>
              <li class="aside-menu__item"><a class="aside-menu__link" href="#">O stronie</a></li>
            </ul>
            <div class="aside-inner"><span class="aside-inner__title">Email</span><a class="aside-inner__link" href="mailto:info@jp2filmy.pl">info@jp2filmy.pl</a></div>
            <div class="aside-inner"><span class="aside-inner__title">Numer telefonu</span><a class="aside-inner__link" href="javascript:void(0);">+48 696 215 611</a></div>
          </div>
          <div class="aside-dropdown__item"><a class="button button--squared" href="mailto:info@jp2filmy.pl"><span>Zgłoś swój film</span></a></div>
          <div class="aside-inner text-center" style="padding: 20px 35px; pointer-events: none"><a class="aside-inner__link" href="javascript:void(0);">Jeśli masz w domu filmy z Janem Pawłem II lub wcześniejsze z Karolem Wojtyłą i chciałbyś aby ująć  je w bazie danych napisz do mnie maila.</a></div>
        </div>
      </div>
    );
  }
}

export default Dropdown;