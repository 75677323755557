import { h, Component, createRef } from 'preact';
import { Router } from 'preact-router';
import { route } from 'preact-router';
import { Link, Match } from 'preact-router/match';

import LogoMinisterstwa from '../components/SVG/LogoMinisterstwa';

class Footer extends Component {

    static hrefs = [
		{ href: '/', name: 'Strona główna' },
		{ href: '/biografia', name: 'Biografia' },
		{ href: '/zbiory', name: 'Zbiory' },
		{ href: '/pielgrzymki', name: 'Pielgrzymki' }
	]

    static sources = [
        ["Ignatianum", "https://pchph.ignatianum.edu.pl"],
        ["Pixabay", "https://cdn.pixabay.com"],
        ["Wojtek Laski", "https://eastnews.pl"],
        ["Małopolska", "https://www.malopolska.pl/"],
        ["Ocdn", "https://ocdn.eu"],
        ["jp2concert", "www.jp2concert.com"],
        ["Wydwanictwo WAB", "https://www.gwfoksal.pl/"],
        ["Deon", "https://www.deon.pl/"],
        ["Wikipedia", "https://wikipedia.org/"],
        ["Diecezja", "https://diecezja.pl/"],
        ["Icons - Freepik", "https://www.freepik.com"]
    ]

    render() {
        return (
            <div>
                <Match path="/">
					{({ matches, path, url }) => (
						(path == "/" || path == "")
						?
						<div class="footer-about">
                            <div class="container">
                                <h4 class="footer__title">O stronie</h4>
                                Strona stanowi zbiór filmów dokumentalnych i fabularnych związanych z Janem Pawłem II, które są dostępne w różnych instytucjach i archiwach. Ma charakter informacyjny, nie zamierzam ukazywać papieża w świetle złym ani dobrym, ani go oceniać. Jest to praca o charakterze archiwalnym. Głównym celem tej strony jest umożliwienie edukatorom, różnym osobom i instytucjom organizującym pokazy filmów zapoznania się z poszczególnymi  tytułami i ułatwienie ich zlokalizowania. Filmy o papieżu są rozproszone w wielu miejscach i dotarcie do nich nie jest łatwe, w wielu przypadkach instytucje nie dysponują katalogami i trzeba przeprowadzić kwerendę żeby się dowiedzieć jakie mają filmy. Oczywiście nie są to wszystkie filmy, które zostały nakręcone o papieżu a jedynie te, które znajdują się w wymienionych archiwach. W miarę pojawiania się w nich nowych tytułów będę się starała uzupełniać katalog. Strona przeznaczona jest dla  wszystkich osób zainteresowanych postacią papieża Jana Pawła II.
                            </div>
                        </div>
						:
						""
					)}
				</Match>

<footer class="footer" style="padding: 35px 0;">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-6 col-lg-3">
                            <h4 class="footer__title">Kontakt</h4>
                            <div class="footer-contacts">
                                <p class="footer-contacts__phone">Telefon: <a>+48 696 215 611</a></p>
                                <p class="footer-contacts__mail">Email: <a href="mailto:info@jp2filmy.pl">info@jp2filmy.pl</a></p>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <h4 class="footer__title">Nawigacja</h4>
                            <nav>
                                <ul class="footer-menu">

                                    {Footer.hrefs.map((answer, i) => {
                                        if (typeof window !== "undefined") {
                                            if (
                                                (answer.href.startsWith("/zbiory") && document.location.pathname.startsWith("/zbiory")) ||
                                                (answer.href.startsWith("/pielgrzymki") && document.location.pathname.startsWith("/pielgrzymki"))
                                            ) {
                                                return (
                                                    <li class="footer-menu__item footer-menu__item--active">
                                                        <Link class={"footer-menu__link"} href={answer.href}><span>{answer.name}</span></Link>
                                                    </li>
                                                )
                                            }
                                            else {
                                                return (
                                                    <li class={"footer-menu__item " + ((document.location.pathname == answer.href) ? "footer-menu__item--active" : "")}>
                                                        <Link class={"footer-menu__link"} href={answer.href}><span>{answer.name}</span></Link>
                                                    </li>
                                                )
                                            }
                                        }
                                    })}


                                </ul>
                            </nav>
                        </div>
                        
                        <div class="col-sm-6 col-lg-3 sources" >
                            <h4 class="footer__title">Materiały</h4>
                            <div class="row">
                                
                                {
                                    Footer.sources.map((e, i) => {
                                        return (
                                            <div class="col-6">
                                                <div class="row justify-content-center">
                                                    <span><a target="_blank" href={e[1]}>{e[0]}</a></span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
       
                        </div>

                        <div class="col-sm-6 col-lg-3">
                            <div class="footer-logo">
                                <a class="footer-logo__link" href="https://www.gov.pl/web/kulturaisport">
                                    <LogoMinisterstwa/>
                                </a>

                                <h6 style="color: white; margin-bottom: 25px; margin-top: 15px; text-align: center; font-size: 16px;">Zrealizowano w ramach stypendium Ministra Kultury, Dziedzictwa Narodowego i Sportu</h6>
                            </div>
                            <ul class="footer-socials">
                                <li class="footer-socials__item"><a class="footer-socials__link" href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                <li class="footer-socials__item"><a class="footer-socials__link" href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li class="footer-socials__item"><a class="footer-socials__link" href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a></li>
                                <li class="footer-socials__item"><a class="footer-socials__link" href="#"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="row align-items-baseline">
                        <div class="col-12">
                            <p class="footer-copyright text-center" style="margin-top: 10px; color: white">Twórca projektu strony - <a target="_blank" href="https://ebert.app"><strong style="color: #e9ba13">Mateusz Ebert</strong></a></p>
                        </div>
                    </div>
                    <div class="row align-items-baseline">
                        <div class="col-12">
                            <p class="footer-copyright text-center" style="margin-top: 10px">©2020-2021 Wszelkie prawa zastrzeżone</p>
                        </div>
                    </div>
                </div>
            </footer>

            </div>
        )

    }

}

export default Footer;
